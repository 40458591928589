// eslint-disable-next-line no-undef
jQuery(document).ready(($) => {
  /* IE popup
  ========================================================================== */

  function checkIfIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
      // eslint-disable-next-line no-alert
      alert(
        "For an optimal viewing experience, please choose a different browser."
      );
    }

    return false;
  }

  checkIfIE();

  /* Set VW without horizontal scrollbar
  ========================================================================== */

  function setVw() {
    const vw = document.documentElement.clientWidth / 100;
    document.documentElement.style.setProperty("--vw", "".concat(vw, "px"));
  }

  setVw();

  window.addEventListener("resize", () => {
    setVw();
  });

  /* Google reCAPTCHA WCAG compliance
  ========================================================================== */

  $(window).on("load", () => {
    $(".g-recaptcha-response")
      .attr("aria-hidden", true)
      .attr("aria-label", "do not use")
      .attr("aria-readonly", true);
  });

  /* Avoid search field id repetitive 
  ========================================================================== */

  $(".search-field").each(() => {
    // element == this
    const id = `navigation-search-field-${Math.floor(Math.random() * 1000)}`;
    $(this)
      .attr("id", id)
      .next("[for='navigation-search-field']")
      .attr("for", id);
  });

  /* Accordion
  ========================================================================== */

  $(".accordion").on("click", (e) => {
    const acc = e.currentTarget;

    if (!$(acc).hasClass("accordion--active")) {
      $(acc)
        .parents(".accordions")
        .first()
        .find(".accordion--active")
        .toggleClass("accordion--active")
        .next(".panel")
        .slideToggle();
    }

    $(acc).toggleClass("accordion--active").next(".panel").slideToggle();
  });

  /* Masonry
  ========================================================================== */

  if ($(".archive.category-blog .site-main")) {
    const $blogs = $(".archive.category-blog .site-main").masonry({
      itemSelector: ".post.category-blog",
      columnWidth: ".post.category-blog:not(:first-of-type)",
      percentPosition: true,
      gutter: 20,
    });

    $(".archive.category-blog .site-main").on("append.infiniteScroll", () => {
      $blogs.masonry("reloadItems").masonry();
    });
  }

  /* AOS
  ========================================================================== */

  const { AOS } = window;

  new Promise((resolve) => {
    document.querySelectorAll(".aos").forEach((el) => {
      el.className.split(/\s+/).forEach((item) => {
        if (item.includes("aos_")) {
          const parts = item.split("_");

          if (parts.length > 2) {
            el.setAttribute(`data-aos-${parts[1]}`, parts[2]);
          } else {
            el.setAttribute("data-aos", parts[1]);
          }
        }
      });
    });

    document.querySelectorAll(".highlight").forEach((el) => {
      el.setAttribute("data-aos", "fade-up");
    });

    setTimeout(() => {
      resolve();
    }, 100);
  })
    .then(() => {
      AOS.init({
        // Global settings:
        disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
        startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
        initClassName: "aos-init", // class applied after initialization
        animatedClassName: "aos-animate", // class applied on animation
        useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
        disableMutationObserver: false, // disables automatic mutations' detections (advanced)
        debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
        throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

        // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
        offset: 120, // offset (in px) from the original trigger point
        delay: 0, // values from 0 to 3000, with step 50ms
        duration: 500, // values from 0 to 3000, with step 50ms
        easing: "ease", // default easing for AOS animations
        once: true, // whether animation should happen only once - while scrolling down
        mirror: false, // whether elements should animate out while scrolling past them
        anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
      });
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
    });

  /* Homepage slider
    ========================================================================== */

  $(".home-header-slider").slick({
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
  });

  /* History timeline
  ========================================================================== */

  if ($(".history-timeline__timeline").length) {
    const centerPadding =
      $(document).innerWidth() > 750
        ? ($(document).innerWidth() - 750) / 2 - 40
        : 0;

    $(".history-timeline__timeline").slick({
      centerMode: true,
      centerPadding: `${centerPadding}px`,
      fade: false,
      speed: 1000,
      autoplayspeed: 2000,
      autoplay: true,
      infinite: false,
      cssEase: "ease",
    });

    const nodesSlidesWithSameDate = $(
      '.history-timeline__navigator-date[data-gotoslide*=","]'
    );
    const slidesWithSameDate = [];

    $.each(nodesSlidesWithSameDate, (indexInArray, valueOfElement) => {
      slidesWithSameDate.push(
        valueOfElement.getAttribute("data-Gotoslide").split(",")
      );
    });

    $(".history-timeline__navigator-date[data-gotoslide]:first-child").addClass(
      "active"
    );

    $(".history-timeline__navigator-date[data-gotoslide]").on("click", (e) => {
      let slideIndex = $(e.target).data("gotoslide");

      if (slideIndex.toString().includes(",")) {
        slideIndex = slideIndex.slice(0, slideIndex.indexOf(","));
      }

      $(".history-timeline__timeline").slick("slickGoTo", slideIndex);
    });

    $(".history-timeline__timeline").on(
      "beforeChange",
      (event, slick, currentSlide, nextSlide) => {
        $(".history-timeline__navigator-date[data-gotoslide]").removeClass(
          "active"
        );
        const slideIndex = nextSlide;
        const slideIndexes = slidesWithSameDate.find((indexes) =>
          indexes.includes(slideIndex.toString())
        );

        if (slideIndexes) {
          $(
            `.history-timeline__navigator-date[data-gotoslide="${slideIndexes.join(
              ","
            )}"]`
          ).addClass("active");
        } else {
          $(
            `.history-timeline__navigator-date[data-gotoslide="${slideIndex}"]`
          ).addClass("active");
        }
      }
    );
  }

  /* History slider
  ========================================================================== */

  $(".history-slider__slides").slick({
    dots: false,
    arrows: false,
    centerMode: true,
    centerPadding: "22%",
    speed: 1000,
    slidesToShow: 2,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: false,
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 1,
          centerPadding: "30%",
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          centerPadding: "25%",
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          centerPadding: "20%",
        },
      },
      {
        breakpoint: 601,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  });

  /* Schools & Stops Search
  ========================================================================== */

  const searchTypes = ["schools", "stops"];

  searchTypes.forEach((searchType) => {
    if (document.getElementById(`${searchType}__search-field`)) {
      document
        .getElementById(`${searchType}__search-field`)
        .addEventListener("input", (event) => {
          if (
            event.inputType === "insertReplacementText" ||
            event.inputType == null
          ) {
            $(`.${searchType}__search-btn`)
              .attr(
                "href",
                document.querySelector(
                  `#${searchType}__search-list > option[value="${event.target.value}"]`
                ).dataset.url
              )
              .fadeIn()
              .css("display", "inline-block");
          } else {
            $(`.${searchType}__search-btn`).attr("href", "").fadeOut();
          }
        });
    }
  });

  /* Operations odometer
  ========================================================================== */

  const { Odometer } = window;

  $(document).on("aos:in:stats-animated", ({ detail }) => {
    $(detail)
      .find(".stats-odometer")
      .each((i, el) => {
        const oldStats = $(el).text();

        new Odometer({
          el,
          value: 0,
          theme: "default",
        }).update(oldStats);
      });
  });
});
